.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* OpenSans */
@font-face {
  font-family: OpenSansBold;
  src: url("./assets/fonts/OpenSans-Bold.ttf");
}

@font-face {
  font-family: OpenSansLight;
  src: url("./assets/fonts/OpenSans-Light.ttf");
}

@font-face {
  font-family: OpenSansMedium;
  src: url("./assets/fonts/OpenSans-Medium.ttf");
}

@font-face {
  font-family: OpenSansRegular;
  src: url("./assets/fonts/OpenSans-Regular.ttf");
}
/* Chivomono */
@font-face {
  font-family: ChivoMonoBold;
  src: url("./assets/fonts/ChivoMono-Bold.ttf");
}

@font-face {
  font-family: ChivoMonoLight;
  src: url("./assets/fonts/ChivoMono-Light.ttf");
}

@font-face {
  font-family: ChivoMonoMedium;
  src: url("./assets/fonts/ChivoMono-Medium.ttf");
}

@font-face {
  font-family: ChivoMonoRegular;
  src: url("./assets/fonts/ChivoMono-Regular.ttf");
}

*:focus {
  outline: none;
}

.PACKAGE_COLOR_1 {
  background-color: #99d1ff;
}
.PACKAGE_COLOR_2 {
  background-color: #06b6d4;
}
.PACKAGE_COLOR_3 {
  background-color: #bef264;
}
.PACKAGE_COLOR_4 {
  background-color: #fdba74;
}
.PACKAGE_COLOR_5 {
  background-color: #fca5a5;
}
.PACKAGE_COLOR_6 {
  background-color: #f87171;
}
